<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <div class="banner">
            <!-- <img src="@/assets/images/service_detail_banner.png" alt="" /> -->
        </div>
        <div class="content max_big_box" ref="header" >
             <!-- <div class="title max_small_box">
                <p >{{detail.name}}</p>
            </div> -->
            <div class="content_first max_small_box">
                    <div class="img" v-if="detail" data-aos="fade-right">
                        <img v-if="detail.image" :src="imgUrl + detail.image" />
                    </div>
                    <div class="text" v-if="detail" data-aos="fade-left">
                        <p class="title">{{detail.name}}</p>
                        <!-- <p class="desc">{{serviceDetail.intro}}</p> -->
                        <!-- <div v-if="detail.detail" class="desc" v-html="detail.detail"></div> -->
                        <p class="desc" v-for="(list, index) in detail.related" :key="index">{{list.title}}{{list.desc}}</p>
                        <!-- <p class="online" id="kefu_button">在线咨询</p> -->
                    </div>
                </div>
            <!-- <div class="line"></div> -->
            <p class="detail_title max_small_box">案例详情</p>
            <!-- <div class="detail max_small_box"  v-html="detail.detail"></div> -->
            <!-- 详情 -->
            <div class="detail_box" v-if="detail">
                <div class="detail max_small_box">
                    <div class="list" v-for="(item, index) in detail.content" :key="index">
                        <p class="title">{{item.title}}</p>
                        <div class="desc" v-html="item.desc"></div>
                        <!-- <div class="imgs" v-if="item.images[0] != ''">
                            <div class="img"  v-for="(list, index) in item.images" :key="index" >
                                <img v-if="list != '' || list != null" :src="imgUrl + list" />
                            </div>
                            
                        </div> -->
                    </div> 
                    <!-- <div class="list" >
                        <p class="title">服务流程</p>
                        <img style="width:70%" src="@/assets/images/b08871ea26d88d03f31abf84c7f08265_.jpg" />
                        </div> 
                    </div>  -->
                </div>
            </div>
            <!-- <div class="line"></div> -->
        </div>
       
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import * as api from '../../utils/api';
import { get, post } from '../../utils/request';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {
    //详情
    const detail = ref()

     //获取详情
    const getDetail = async (id) => {
        let postData = {
            id: id,
        }
        try{
            const result = await post('/about/get_about_case_detail', postData)
            // console.log('案例详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    return { detail, getDetail }
}

export default {
    name: 'DaseDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            getDetail(route.params.id)  //获取详情
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail }
    }
}
</script>

<style lang="scss" scoped>
/* 轮播图 */
.banner{
    margin-top: 114px;
    border-bottom: #e5e5e5 solid 1px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
.content{
    margin-top: 30px;
    .content_first{
        display: flex;
        @media screen and (max-width: 1200px){
            display: block;
        }
        .img{
            width: 20%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .text{
            width: 60%;
            padding: 0 0% 0 30px;
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            @media screen and (max-width: 1200px){
                width: auto;
                padding: 30px 30px;
            }
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 30px;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 10px;
                line-height: 20px;
            }
            /* .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
                text-align: justify;
                 @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            } */
            .online{
                transition: all 0.36s ease;
                border: 1px solid #101010;
                display: inline-block;
                font-size: 14px;
                line-height: 30px;
                color: #101010;
                padding: 0px 20px;
                margin-bottom: 20px;
                width: max-content;
                cursor: pointer;
                margin-top: 15px;
            }
        }
    }
    .title{
        @media screen and (max-width: 1200px){
            /* padding: 0 30px; */
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        margin-top: 30px;
        line-height: 25px;
    }
    .line{
    height: 1px;
    background-color: #bbb;
    margin-top: 40px;
}
/* 详情 */
.detail_title{
    color: #305aa8;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
    @media screen and (max-width: 1200px){
        padding-left: 30px;
    }
    /* border-bottom: 1px solid linear-gradient(to bottom right, red, yellow); */
    /* background-image: linear-gradient(to bottom right, red, yellow); */
}
.detail_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
}
.detail{
    /* display: flex; */
    flex-wrap: wrap;
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 80%;
        margin-right: 4%;
        margin-bottom: 50px;
        overflow-x: auto;
        &:nth-child(2n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 18px;
            font-weight: 600;
            /* &:after{
                content: "  ";
                width: 100%;
                height: 1px;
                display: block;
                background-color: #e2e2e2;
            } */
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            margin-top: 14px;
            line-height: 24px;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            .img{
                width: 21%;
                object-fit: cover;
                margin-right: 5%;
                margin-bottom: 15px;
                &:nth-child(4n){
                    margin-right: 0%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}
}

</style>